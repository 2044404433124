// Color
// -------------------------
$blue            : var(--blue);
$indigo			 : var(--indigo);
$purple			 : var(--purple);
$pink			 : var(--pink);
$red             : var(--red);
$orange			 : var(--orange);
$yellow          : var(--yellow);
$green           : var(--green);
$teal            : var(--teal);
$cyan            : var(--cyan);;
$white           : var(--white);
$gray            : var(--gray);
$gray-dark       : #343a40 !default;
$primary         : #007bff !default;
$secondary       : #6c757d !default;
$success         : $green !default;
$info 			 : $cyan !default;
$warning 		 : $yellow !default;
$danger 		 : var(--red);
$light 		 	 : var(--light);
$dark 		 	 : #343a40 !default;
$black           : var(--black);
$nocolor         : transparent !default;
$yellow-dark     : #f2cb2e !default;
$red-dark        : #ce4f3e !default;
$turquoise       : #9c619c !default;
$brown-light	 : #eb5454 !default;
$light-gray      : lighten(#000000, 60%) !default;       
$very-light-gray : #e5e5e5 !default;                      
$very-dark-gray  : lighten(#000000, 9%) !default;         
$gray-darker     : #0d292f !default;                      
$gray-light      : lighten(#000, 46.7%) !default;         
$gray-lighter    : lighten(#000, 93.5%) !default;         

$brand-primary   : #000 !default;
$brand-success   : $success !default;
$brand-info      : $info !default;
$brand-warning   : $warning !default;
$brand-danger    : $danger !default;
$color-overlay	 : rgba(0,0,0,.5) !default;
 

//== Path
//
//# Path font, font-name
$font-path			:"../fonts/" !default;
$font-name 			:"Montserrat" !default;

$icon-font-path 	:"../fonts-icon/";
$image-path 		:"../img/";

//== Typography  
//
//## Font, line-height, and color for body text, headings, and more.
$font-family-base	:'Montserrat', Arial, sans-serif !default;
$font-family-icon	:'Line Awesome Free' !default;

//icon: content: icon-content($code-icon)
$icon_up 			: \f106!default;
$icon_down 			: \f107!default;
$icon_cut			: \f0c4!default;
$icon_next			: \f105!default;
$icon_prev			: \f104!default;
$icon_check			: \f00c!default;
$icon_pencil		: \f303!default;
$icon_plus			: \f067!default;
$icon_minus			: \f068!default;
$icon_image			: \f03e!default;
$icon_video			: \f008!default;
$icon_file			: \f15c!default;
$icon_start			: \f005!default;

$font-size-base:          14px !default;
$font-size-small:         13px;
$font-size-tiny:          12px;
$font-size-very-tiny:     10px;
$font-size-large:         16px !default;
$font-size-extra-large:   18px !default;
$font-size-icon:		  20px !default;

$font-size-h1 : 24px !default;
$font-size-h2 : 20px !default;
$font-size-h3 : 18px !default;
$font-size-h4 : 16px !default;
$font-size-h5 : 13px !default;
$font-size-h6 : 11px !default;

$font-weight-base: 400 !default;

$font-size-lg                    : 48px !default;
$font-size-md                    : 35px !default;
$font-size-sm                    : $font-size-h5 !default;
$font-size-xs                    : $font-size-h6 !default;
$text-lead-font-size             : 18px !default;
$icon-font-size-base             : 14px !default;
$headings-transform              : none !default;
$text-hightlight                 : $black !default;
$close-font-size                 : 20px !default;


// Border color
// -------------------------
$border-color  					 : #e3e3e3 !default;
$border-attribute				 : #b8b7bd !default;

//** Global text color.
$text-danger					 : var(--red);
$text-success					 : #390 !default;
$text-primary					 : #5867dd !default;
$text-secondary					 : #e1e1ef !default;
$text-info					 	 : #5578eb !default;
$text-warning					 : #ffb822 !default;
$text-light					 	 : $light !default;
$text-dark					 	 : #343a40 !default;
$text-muted					 	 : #74788d !default;
$text-color                      : var(--color-text); //-----666
$text-color-second	          	 : var(--color-main);//-----222
$text-color-third              	 : var(--color-hightlight); //-----c33
$text-color-four              	 : #a6a6a6 !default;
$text-color-five              	 : #e6e6e6 !default;

$dropdown-bg:                    $white !default;
$dropdown-border:                $border-color !default;
$dropdown-color-box-shadow:		 rgba(0,0,0,.15);
$dropdown-divider-bg:            #e6e6e6 !default;

//== Slider
//
//## 
$slider-title-size					 : 24px !default;
$slider-color						 : $text-color-second !default;
$slider-line-height-base			 : 36px !default;
$slider-size-large					 : 85px !default;
$slider-line-height-large			 : 90px !default;
$slider-height						 : 670px !default;
$slider-bg						 	 : #f1f1f1 !default;

//== Aspect Ratio
//
//## 
$ratio-1-1							 : 100% !default;
$ratio-16-9							 : 56.25% !default;
$ratio-4-3							 : 75% !default;
$ratio-3-2							 : 66.66% !default;
$ratio-8-5							 : 62.5% !default;
$ratio-custome						 : 130% !default;

//== Menu
//
//## Convenience function used to set content property
@function icon-content($code-icon) {
  @return unquote("\"#{$code-icon}\"");
}

// menu 
$title-menu: $text-color-second !default;
$link-menu: $text-color !default;
$hover-menu: $text-color-third;
$menu-color : #888;
$menu-weight-bold				 : 600 !default;
$menu-icon-child				 : \f078  !default;
$menu-icon-size				 	 : 12px  !default;
// end menu

//** Dropdown link text color.
$dropdown-link-color:            #333 !default;
$dropdown-link-hover-color:      #333 !default;
$dropdown-link-hover-bg:         #f4f4f4 !default;

//** Active dropdown menu item text color.
$dropdown-link-active-color:     #333 !default;
$dropdown-link-active-bg:        #f4f4f4 !default;
$dropdown-caret-color:           #000 !default;

//** Dropdown header text color
$dropdown-header-color:          #666 !default;

//** Global textual link color.
$link-color:             $text-color !default;
$link-hover-color:       $text-color-third !default;
$line-height-base:       1.7 !default;

//== MiniCart
//
//## 
$minicart-icon-color			 :$light;

//== icon
//
//##
$icon-color:			 #222 !default;
$icon-hover:			 lighten(#222, 60%) !default;

//== Forms
//
//##

$input-bg:                       $light !default;
$input-color:                    $black !default;
$input-border-color:             #dee2e6 !default;
$input-border-radius:            0px !default;
$input-border-focus:             #66afe9 !default;
$input-height-base:              40px !default;
$button-color:                   $white;
$button-bg:                      $text-color-second;
$button-hover:                   $text-color-third;

$button-border-radius:			 0px !default;

//-- Z-index master list
//
$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-sticky:            1010 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-menu:              1100 !default;
$zindex-toolbar:           1120 !default;
$zindex-navbar-fixed:      1030 !default;
$zindex-modal-background:  1040 !default;
$zindex-modal:             1050 !default;
$zindex-modal-close:       1000 !default;
$zindex-mini-cart:	       1030 !default;
$zindex-overlay:	       1020 !default;
$zindex-change-image:      10 !default;
$zindex-atttribute:   	   40 !default;
$zindex-atttribute-overlay:30 !default;
$zindex-product-action:	   20 !default;
$zindex-quick-shop:		   50 !default;



$overlay_dark_fallback			 : #111111 !default; 
$overlay_dark 					 : #333333 !default; 
$overlay_dark_dark				 : #111111 !default; 

//== Product Block
//
//##
$heading-size			 		 : 36px !default;
$heading-color					 : $text-color-second !default;
$background-out-stock			 : $black !default;
$background-onsale				 : $red !default;
$background-featured			 : $green !default;

//== Product Detail
//
//##
$product-size-price				 : 24px !default;
$product-size-price-old			 : 18px !default;
$product-size-qty				 : 25px !default;

//product small
$border-color 					 : #e1e1e1 !default;

//== Footer
//
//##
$bg-footer						 : $light;
$font-size-icon				 	 : 18px !default;
$footer-title-color				 : $text-color-second;
$footer-color					 : #666 !default;
$footer-color-second	         : #7a7a7a!default;
$footer-color-third              : #54595f!default;

//== social
//
//##
$color-facebook					 : #365493;
$color-twitter					 : #3cf;
$color-google					 : #d44132;
$color-pinterest				 : #cb2027;
$color-linkedin				 	 : #0274b3;

//== mini cart
//
//##
$minicart-size-large			 : 18px !default;
$minicart-size-extra-large		 : 24px !default;
$icon-close						 : 30px !default;
$icon-trash						 : 16px !default;
$minicar-color					 : #390 !default;

//== Page info
//
//##
$page-info-title-size			 : 48px !default;
$page-info-margin-bottom		 : 60px !default;
$page-info-background			 : #f3f3f3 !default;
$page-info-padding				 : 45px 0 !default;
$page-info-size					 : 16px !default;
$page-info-color				 : #999 !default;
$page-info-color-current		 : $black !default;

//cart item 
$cart-item-price-color			 : #390 !default;
$cart-item-price-product		 : $text-color-second !default;
$cart-item-size					 : 16px !default;
$cart-item-size-large			 : 16px !default;
$cart-item-background			 : #f7f7f7 !default;
$cart-item-size-total			 : 24px !default;
$cart-alert-bg					 : #dff0d8 !default;
$cart-alert-color				 : #3c763d !default;

//alert error
$alert-icon						 : 80px !default;
$alert-font-large				 : 18px !default;

// loading element
$loading-color					 : #666 !default;
$loading-overlay				 : #fff !default;
$loading-opacity				 : 0.7 !default;	
$loading-index					 : 1100 !default;		

// breadcrums
$breadcrums-color                : $text-color;
$breadcrums-background			 : $light;
$breadcrums-active				 : $text-color-second;
$breadcrums-hover                : $text-color-second;
// end breadcrums

//comment
$comment-bg-reply				 : $light;
//end comment

// order by
$orderby-bg-hover				 : #f6f6f6 !default;
// end order by

// tags
$tags-bg-hover				 : #f1f1f1 !default;
// end tags


//responsive
$mobile-width: 576px; 
$tablet-width: 768px; 
$desktop-width: 992px;


//slick
$slick-font-family: $font-family-icon;
$slick-loader-path: 'img/' !default;
$slick-font-weight: 900 !default;
$slick-arrow-color: #000 !default;
$slick-dot-color: #000 !default;
$slick-dot-color-active: #000 !default;
$slick-prev-character: \f104!default;
$slick-next-character: \f105!default;
$slick-up-character: \f106!default;
$slick-down-character: \f107!default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}